module.exports = function init (serviceLocator) {

  serviceLocator.logger.info('Initializing Style Guide Navigation')

  var $navigation = $('.js-sg-nav')
    , $toggle
    , $links

  if ($navigation.length === 0) return

  $toggle = $('.js-sg-nav-toggle', $navigation)

  $toggle.on('click', function () {
    $navigation.toggleClass('is-active')
  })

  $links = $('.js-sg-nav-link', $navigation)

  if ($links.length === 0) return

  $links.on('click', function (e) {
    e.preventDefault()

    var $this = $(this)

    $this.toggleClass('is-active')
  })

}
