module.exports = function init (serviceLocator) {

  serviceLocator.logger.info('Initializing Navigaiton Log In')

  var $navigaitonLogin = $('.js-navigation-login-link')
    , $navigaitonLoginControl = $('.js-navigation-login')

  if ($navigaitonLogin.length === 0) return

    $navigaitonLogin.on('click', function (e) {
      var $this = $(this)
      e.preventDefault()
      $('.navigation-login').toggleClass('is-active')
      $('.main-navigation .main-navigation-mobile__links').removeClass('is-visible')
      $navigaitonLoginControl.insertAfter($this)
    })

}
