module.exports = function init (serviceLocator) {

  serviceLocator.logger.info('Initializing FAQs')

  var $faq = $('.js-faq')

  if ($faq.length === 0) return

  $faq.each(function () {
    var $this = $(this)

    $this.addClass('is-closed is-toggleable')
  })

  $('h4', $faq).on('click', function () {
    var $this = $(this)

    $this.parent('.js-faq').toggleClass('is-closed')
  })

}
