module.exports = function init (serviceLocator) {

  serviceLocator.logger.info('Initializing Hamburger Menu Pages')

  var $hamburger = $('.js-hamburger')
    , $navigation = $('.main-navigation')

  $hamburger.on('click', function () {
    $navigation.toggleClass('navigation-active')
    $('.main-navigation .main-navigation-mobile__links').toggleClass('is-visible')
    $('.navigation-login').removeClass('is-active')
  })

}
