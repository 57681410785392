module.exports = bootstrap

var components =
  [ require('./components/hamburger-menu-pages/init.js')
  , require('./components/style-guide-nav/init.js')
  , require('./components/navigation-log-in/init.js')
  , require('./components/how-it-works/init.js')
  , require('./components/testimonials/init.js')
  , require('./components/faqs/init.js')
  , require('./components/insurers/init.js')
  ]

function bootstrap (serviceLocator) {
  components.forEach(function (component) {
    component(serviceLocator)
  })
}
