module.exports = function init (serviceLocator) {

  serviceLocator.logger.info('Initializing Insurers')

  var $insurer = $('.js-insurer')
    , $dummy = $('.js-insurer-dummy')
    , $container
    , count
    , limit
    , timeout

  if ($insurer.length === 0) return

  $(window).on('resize', function () {
    clearTimeout(timeout)
    loadInsurers()

    if ($dummy.css('display') !== 'none') {
      displayInsurers()
    }
  }).resize()

  function loadInsurers () {
    $container = $('.js-insurer-container')
    limit = $container.data('limit')
    count = 0

    $insurer.addClass('is-invisible').css({ opacity: '' })

    while (count < limit) {
      var index = Math.floor(Math.random() * $insurer.length) + 0
        , $elem = $insurer.eq(index)

      if ($elem.hasClass('is-invisible')) {
        $elem.removeClass('is-invisible')

        count++
      }
    }

    $insurer.filter('.is-invisible').insertAfter($insurer.filter(':not(.is-invisible)').last())
  }

  function displayInsurers () {
    timeout = setTimeout(function () {
      var hidden = $insurer.filter('.is-invisible')
        , visible = $insurer.filter(':not(.is-invisible)')
        , hiddenIndex = Math.floor(Math.random() * hidden.length) + 0
        , visibleIndex = Math.floor(Math.random() * visible.length) + 0
        , $visibleElem = visible.eq(visibleIndex)
        , $hiddenElem = hidden.eq(hiddenIndex)

      $hiddenElem.insertAfter($visibleElem)

      $visibleElem.animate({ opacity: 0 }, 1000, function () {
        $hiddenElem.animate({ opacity: 1 }, 1000, function () {
          $visibleElem.addClass('is-invisible')
          $hiddenElem.removeClass('is-invisible')
        })
      })

      displayInsurers()
    }, 3000)
  }

}
