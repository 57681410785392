module.exports = function init (serviceLocator) {

  serviceLocator.logger.info('Initializing Testimonials')

  var $testimonial = $('.js-testimonial')
    , $controls
    , $dots
    , $dot
    , $arrow
    , activeIndex = 0
    , i
    , maxLength = $testimonial.length - 1

  if ($testimonial.length === 0) return

  $controls = $('.js-testimonial-controls')
  $dots = $('.js-testimonial-dots')
  $arrow = $('.js-testimonial-arrow')

  if ($testimonial.length > 1) {
    $controls.addClass('is-visible')

    for (i = 0; i < $testimonial.length; i++) {
      $dots.append('<li class="js-testimonial-dot"></li>')
    }
  }

  $dot = $('.js-testimonial-dot')

  $(window).on('resize', function () {
    resizeTestimonials()
    displayTestimonials()
  }).resize()

  $('.js-testimonial-arrow').on('click', function () {
    var $this = $(this)
      , direction = $this.attr('data-direction')

    switch (direction) {
      case 'previous':
        activeIndex--
        break
      case 'next':
        activeIndex++
        break
    }

    if (activeIndex < 0) activeIndex = 0
    if (activeIndex > maxLength) activeIndex = maxLength

    displayTestimonials()
  })

  function displayTestimonials () {
    $testimonial.addClass('is-hidden').eq(activeIndex).removeClass('is-hidden')
    $dot.removeClass('is-active').eq(activeIndex).addClass('is-active')
    $arrow.addClass('is-disabled')

    if (activeIndex > 0) $arrow.eq(0).removeClass('is-disabled')
    if (activeIndex < maxLength) $arrow.eq(1).removeClass('is-disabled')
  }

  function resizeTestimonials () {
    var maxHeight = 0

    $testimonial.removeClass('is-hidden').css('height', 'auto').each(function () {
      var $this = $(this)
        , height = $this.height()

      if (height > maxHeight) maxHeight = height
    }).css('height', maxHeight)
  }

}
