module.exports = function init (serviceLocator) {

  serviceLocator.logger.info('Initializing How It Works')

  var $steps = $('.js-how-it-works-step')
    , $controls = $('.js-how-it-works-controls')
    , $arrows = $('.js-how-it-works-arrow')
    , activeIndex = 0
    , maxLength = $steps.length - 1

  if ($steps.length === 0) return

  if ($steps.length > 1) {
    $controls.addClass('is-visible')
  }

  $arrows.on('click', function () {
    var $this = $(this)
      , direction = $this.attr('data-direction')

    switch (direction) {
      case 'previous':
        activeIndex--
        break
      case 'next':
        activeIndex++
        break
    }

    if (activeIndex < 0) activeIndex = 0
    if (activeIndex > maxLength) activeIndex = maxLength

    displaySteps()
  })

  displaySteps()

  function displaySteps () {
    $steps.addClass('is-inactive').eq(activeIndex).removeClass('is-inactive')

    $arrows.addClass('is-disabled')

    if (activeIndex > 0) $arrows.eq(0).removeClass('is-disabled')
    if (activeIndex < maxLength) $arrows.eq(1).removeClass('is-disabled')
  }

}
